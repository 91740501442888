import React from 'react';
import './App.css';
import {
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";


// audio player
import AudioPlayer from 'react-h5-audio-player';




// TODO: Replace the following with your app's Firebase project configuration
var firebaseConfig = {
  apiKey: "AIzaSyDquIdmKQfr-QGF3kyEb28FzAboOPBE36g",
  authDomain: "disco-6a3bf.firebaseapp.com",
  databaseURL: "https://disco-6a3bf.firebaseio.com",
  projectId: "disco-6a3bf",
  storageBucket: "disco-6a3bf.appspot.com",
  messagingSenderId: "71660981931",
  appId: "1:71660981931:web:d9e0d2e4e7552bfcbfb1ee"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    color: 'white'

  },
  margin: {
    margin: theme.spacing(1),
    color: 'white'
  },
  input: {
    color: "white"
  }
}));

var ReactRotatingText = require('react-rotating-text');


function addEmail() {
  var db = firebase.firestore();
  var inputEmail = document.getElementById("emailinput").value;
  var thanks = document.getElementById("thanks");

  console.log(inputEmail);

  db.collection("alpha").add({
    email: inputEmail
  })
    .then(function (docRef) {
      console.log("Document written with ID: ", docRef.id);
      thanks.classList.remove("d-none");
   })
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
    console.log("done???");
}

function App() {

  const classes = useStyles();

  return (
    <div className="App">
      {/* background stuff */}
      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>

      {/* Disco Sign Up */}

      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        {/* stuff here under logo */}
        <h1 className="title font-weight-bold mt-4 pb-2">  <i> DISCO LIVE</i> </h1>


        <h2 className="roto">Discover <span className="cycleColor"><ReactRotatingText items={['New Music', 'New Artists', 'Your Sound', 'Your Community', "New Playlists", "Events around You", 'The Next Wave']} /></span></h2>




        <div className="form-inline my-3 d-none d-lg-block">
          <label className="sr-only" htmlFor="inlineFormInputName2">Name</label>
          <input type="text" className="form-control mb-1 mr-sm-2 bg-transparent signUp text-warning" id="emailinput" placeholder="Enter email for invite"  />


          <button className="btn btn-warning mb-1" type = "email" onClick = {addEmail} >Submit</button>
        </div>


        <div className="container mt-2 d-lg-none">
          <div className="row">
            <div className="col-9">
              <input type="text" className="form-control  w-100 bg-transparent signUp text-warning" id="emailinput" placeholder="Enter email for invite" />

            </div>
            <div className="col-3 ml-0 pl-0">
              <button className="btn btn-warning mb-1" type = "email" onClick = {addEmail} >Submit</button>

            </div>
          </div>
        </div>
        <p className= "text-center text-warning thanks d-none" id= "thanks">Thanks for applying! We'll be in touch</p>




      </header>

      <br />

      {/* Artist Profiles */}
      <div>

        <h1 className="display-5 text-light font-weight-light"> MEET OUR ARTISTS</h1>

        <div className="container my-3">

          <div className="row">
            <div className="col">
              {/* details */}
              <div>
                <img src="/avatars/chris.jpg" alt="..." className="rounded-circle artistImage m-2" />
                <h2 className="text-light"> Chris Pree</h2>
                <h5 className="text-warning"><i>Los Angeles</i></h5>

              </div>
              {/* song player */}

            </div>



          </div>
        </div>
        {/* if anything in background  */}
      </div>


      {/* Footer Player */}

      <footer>
        <div className="soundbar-desktop d-none d-lg-block">
          <div className="row">
            <div className="col-3">

              <div className="row">
                <div className="col-3">
                  <img src="/covers/IMG_0696.jpg" alt="cover art" className="coverArt" />

                </div>
                <div className="col">
                  <div className="text-left ml-5">
                    <h2 className="det1 mt-3">Pink Lettuce</h2>
                    <h3 className="det2 text-danger">Chris Pree <small></small></h3>
                    <h4 className="det3"></h4>
                  </div>

                </div>
              </div>


            </div>
            <div className="col">
              <AudioPlayer
                // autoPlay
                src="https://firebasestorage.googleapis.com/v0/b/disco-6a3bf.appspot.com/o/songs%2FPINKLETTUCE.mp3?alt=media&token=c1793f26-c79f-4b6b-8117-d58e2e3f8316"
                onPlay={e => console.log("onPlay")}
                header="Rap | HipHop | Smooth | R&B | Bass"
                customVolumeControls={[]}
                customAdditionalControls={[]}
              // other props here
              />

            </div>
            <div className="col-3">

              <p className="mt-5">coming soon</p>

            </div>
          </div>

        </div>
        <div className="soundbar-mobile d-none"></div>
      </footer>
    </div>
  );
}

export default App;
